import React, { Component, PureComponent } from 'react';
import './index.scss'
import {NavLink, Link} from 'react-router-dom'
import AboutUsPage from './About'

import mobilecheck from './mobilecheck'

const IS_MOBILE = mobilecheck()

export const customOrderAppPaths = ["/", "/menu", "/order"]

export {AboutUsPage as about}


export const pages = [
  {path: "/order", label: "Order"},
  {path: "/menu", label: "Menu"},
  // {path: "/about", label: "Our Concept"},
  // {path: "/about", label: "Catering"}
]

export class SiteNav extends PureComponent {
  render() {

    return (
    <nav className="pages-nav">
      <ul>
        {pages.map(({path, label}) => (
          <li><NavLink className="page-link" exact to={path}>{label}</NavLink></li>
        ))}
      </ul>
    </nav>
  )}
}



export class Header extends Component {
  render() {
    return (
      <div className="landing-page-wrapper">
        <div className="landing-page">
          {/* <div class="video-container">
            {IS_MOBILE && <div className="fire-bg">

            </div>}

            {!IS_MOBILE && <video autoPlay loop muted preload className="video-bg" id="video-bg">
              <source src="https://media.istockphoto.com/videos/cooking-with-wok-video-id947291930"
              type="video/mp4" alt="HTML5 background video" />
              <source src="https://media.istockphoto.com/videos/cooking-with-wok-video-id947291930"
                type="video/webm" alt="HTML5 background video" />
            </video>}
            <div className="splash">
              <div className="section-header">

                <h2>TEYA THAI</h2>
                <p className="catch-phrase">Best Thai in Village Valley</p>
                <p>family owned</p>
                <Link to="/order" className="order-online-link">Order Now</Link>
                <p>Togo & Delivery</p>
              </div>
            </div>
          </div> */}


            <div className="text">
              {/* <p><img className="logo-inline" alt="Crave Cafe" src="https://d2abh92eaqfsaa.cloudfront.net/image/upload/v1553369793/danstuff/Crave_Cafe.png" alt=""/> West Hills</p> */}

              {/* <p>Sun-Thu 7am-2am</p> */}
              {/* <p>Fri-Sat 7am-4am</p> */}
              <h2>TEYA THAI</h2>
              <p className="catch-phrase">Best Thai in Village Valley</p>
              <p>family owned</p>
              <Link to="/order" className="order-online-link">Order Now</Link>
              <p>Togo & Delivery</p>


              {/* <p className="address">22950 Vanowen St.</p> */}
              {/* <Link to="/order" className="order-online-link">Order Togo Below</Link> */}
              {/* <SignUpForm></SignUpForm> */}

            </div>




      </div>
      </div>
    );
  }
}

export class OrderingHeader extends PureComponent {
  render() {
    return (
      <div className="ordering-header-wrapper">
        <div className="ordering-header">

          <div
            className="text"
             >
            <h2>Start your order below</h2>
            <p></p>
          </div>
        </div>
      </div>
    );
  }
}

export function subMenuFilter (subMenu) {
  const tag = subMenu.tag
  if (!tag) {return true}

  const now = new Date()
  const day = now.getDay() // Sunday - Saturday : 0 - 6
  const hours = now.getHours() //0 to 23
  const minutes = now.getMinutes() // 0 to 59
  const isWeekday = [1,2,3,4,5].indexOf(day) !== -1
  const isLunch = (
    (hours >= 11) && (minutes >= 30) && (hours < 15)
  ) || (
    (hours >= 5) && (hours < 15)
  )
  const showLunchMenu = isWeekday && isLunch

  const isLunchSubmenu = tag.indexOf('lunch') !== -1
  const isDinnerSubmenu = tag.indexOf('dinner') !== -1

  if (isLunchSubmenu && showLunchMenu) { return true }
  if (isDinnerSubmenu && !showLunchMenu) { return true }

  return false
}
