
import * as Components from "./Teya"

export default function getCustomComponent () {
  return Components
}

export function getShopId () {
  return "teya"
}
